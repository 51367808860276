import type {
  MediaDataMessage,
  RecorderClientMessage as OriginalRecorderClientMessage,
} from '@remento/types/interview/recorder';
import pRetry from 'p-retry';
import RecordRTC from 'recordrtc';
import fixWebmDuration from 'webm-duration-fix';

import { logger } from '@/logger';
import { isInApp } from '@/modules/routing/utils/app';
import { captureException } from '@/utils/captureException';

import {
  INTERVIEW_SESSION_EXPIRATION_TIME_MS,
  InterviewSessionRepository,
} from '../interview-session/interview-session.types';
import { MediaRecorder } from '../media-recorder/media-recorder';
import { MediaRecorderStateType } from '../media-recorder/media-recorder.types';
import { RecorderMediaStream } from '../media-stream/media-stream';
import { RecorderMediaStreamStateType } from '../media-stream/media-stream.types';
import { DefaultPartitionQueue, MEDIUM_PRIORITY } from '../partition-queue/partition-queue';
import { PartitionQueue, PartitionQueueRepository } from '../partition-queue/partition-queue.types';
import { QueueSynchronizer } from '../queue-synchronizer/queue-synchronizer';
import { QueueSynchronizerStateType } from '../queue-synchronizer/queue-synchronizer.types';
import { RecordingSessionService } from '../recording-session/recording-session.types';
import { StateMachine } from '../state-machine';
import { matchState, StateMatcher } from '../state-matcher';
import { SubscriptionGroup } from '../subscription-group';

import {
  InactiveMediaStreamError,
  MissingMediaTrackError,
  RecordingInterruptedError,
} from './interview-manager.errors';
import { InterviewManagerState, InterviewManagerStateType } from './interview-manager.types';

export interface InitializePayload {
  sessionId: string;
  imageId: string | null;
  promptId: string;
  promptQuestion: string;
  mediaStream: MediaStream;
  type: 'audio' | 'video';
  recorderUserRuid: string | null;
  recorderUserId: string | null;
  recorderPersonId: string | null;
}

export interface RecoverPayload {
  sessionId: string;
  imageId: string | null;
  promptId: string;
  promptQuestion: string;
  type: 'audio' | 'video';
  recorderUserRuid: string | null;
  recorderUserId: string | null;
  paused: boolean;
}

export type RecorderClientMessage = Exclude<OriginalRecorderClientMessage, MediaDataMessage> | [MediaDataMessage, Blob];

export type InterviewPartitionQueueRecord = {
  recording: RecorderClientMessage;
};

export class InterviewManager extends StateMachine<InterviewManagerState> {
  public readonly queue: PartitionQueue<InterviewPartitionQueueRecord>;
  public readonly mediaStream: RecorderMediaStream;
  public readonly mediaRecorder: MediaRecorder;
  public readonly queueSynchronizer: QueueSynchronizer<RecorderClientMessage, 'recording'>;

  private sequenceId = 0;
  private duration = 0;
  private promptSentTimestamp = 0;
  private recording: Blob[] = [];

  private lastMediaDataReceivedTimestamp = 0;
  private mediaDataHealthCheckIntervalId: ReturnType<typeof setInterval> | null = null;

  private subscriptions = new SubscriptionGroup();
  private wakeLockPromise: Promise<WakeLockSentinel> | null = null;

  constructor(
    private recordingSessionService: RecordingSessionService,
    private sessionRepository: InterviewSessionRepository,
    private partitionQueueRepository: PartitionQueueRepository<InterviewPartitionQueueRecord>,
    private maxRecordingDuration: number,
    private debug = false,
  ) {
    super({
      type: InterviewManagerStateType.Empty,
    });

    this.queue = new DefaultPartitionQueue<InterviewPartitionQueueRecord>(
      partitionQueueRepository,
      Number.MAX_SAFE_INTEGER,
    );

    // Recorder
    this.mediaStream = new RecorderMediaStream();
    this.mediaRecorder = new MediaRecorder();
    this.queueSynchronizer = new QueueSynchronizer<RecorderClientMessage, 'recording'>(
      sessionRepository,
      recordingSessionService,
      this.queue,
      'recording',
      MEDIUM_PRIORITY,
    );

    // Transition to the error state if the synchronization fails
    this.subscriptions.add(
      this.queueSynchronizer.subscribeType(
        QueueSynchronizerStateType.UnrecoverableError,
        ({ error }) => {
          const state = this.getState();
          if (state.type === InterviewManagerStateType.Empty || state.type === InterviewManagerStateType.Destroyed) {
            // This will never happen.
            return;
          }
          this.setState({
            type: InterviewManagerStateType.UnrecoverableError,
            sessionId: state.sessionId,
            promptId: state.promptId,
            imageId: state.imageId,
            promptQuestion: state.promptQuestion,
            origin: 'queue-synchronizer',
            error,
            recordings: state.recordings,
          });
          captureException(error, true);
        },
        { once: true },
      ),
    );

    // Transition to the finished state when the synchronization finishes
    this.subscriptions.add(
      this.queueSynchronizer.subscribeType(
        QueueSynchronizerStateType.Finished,
        async () => {
          const state = matchState(this.getState())
            .withType(InterviewManagerStateType.Finishing, StateMatcher.returnState)
            .otherwise(() => {
              throw new Error('The InterviewManager is not in the Finishing state');
            });

          // Start processing the recording
          try {
            await pRetry(() => this.recordingSessionService.finishUpload(state.sessionId, state.promptId), {
              retries: 5,
            });
          } catch (error) {
            logger.error('INTERVIEW_MANAGER.PROCESSING_TRIGGER_FAILED', state, error as Error);
            this.setState({
              ...state,
              type: InterviewManagerStateType.UnrecoverableError,
              origin: 'manager',
              error,
            });
            return;
          }

          this.setState({
            ...state,
            type: InterviewManagerStateType.Finished,
          });
        },
        {
          once: true,
        },
      ),
    );

    // Pause the recording when the storage is full/almost full
    // this.queueSynchronizer.subscribeType(QueueSynchronizerStateType.AlmostOutOfStorage, () => this.pauseRecording());
    // this.queueSynchronizer.subscribeType(QueueSynchronizerStateType.OutOfStorage, () => this.pauseRecording());

    const releaseWakeLock = async () => {
      if (this.wakeLockPromise) {
        const wakeLock = await this.wakeLockPromise;
        this.wakeLockPromise = null;
        if (!wakeLock.released) {
          await wakeLock.release();
        }
      }
    };

    this.subscriptions.add(
      this.subscribeType(
        InterviewManagerStateType.Finished,
        async (state) => {
          releaseWakeLock();
          // Clear all listeners
          this.subscriptions.destroy();
          // Delete the session locally when done
          await this.deleteSession(state.sessionId);
        },
        {
          once: true,
        },
      ),
    );
    this.subscriptions.add(
      this.subscribeType(InterviewManagerStateType.UnrecoverableError, releaseWakeLock, {
        once: true,
      }),
    );
  }

  private async enqueueMessage(msg: RecorderClientMessage) {
    try {
      await this.queueSynchronizer.send(msg);
    } catch (error) {
      const state = matchState(this.getState())
        .withType(InterviewManagerStateType.Empty, StateMatcher.returnNull)
        .withType(InterviewManagerStateType.Destroyed, StateMatcher.returnNull)
        .otherwise(StateMatcher.returnState);
      if (state === null) {
        return;
      }

      logger.error('INTERVIEW_MANAGER.QUEUE_SYNCHRONIZER.SEND_FAILED', { state }, error as Error);

      this.setState({
        ...state,
        type: InterviewManagerStateType.UnrecoverableError,
        origin: 'queue-synchronizer',
        error: new RecordingInterruptedError('queue-disconnected'),
      });

      throw error;
    }
  }

  async initialize(payload: InitializePayload): Promise<void> {
    logger.info('INITIALIZE', {
      sessionId: payload.sessionId,
      promptId: payload.promptId,
      imageId: payload.imageId,
      type: payload.type,
      mediaStreamId: payload.mediaStream?.id,
      promptQuestion: payload.promptQuestion,
    });

    // Validate current state
    const state = matchState(this.getState())
      .withType(InterviewManagerStateType.Empty, StateMatcher.returnState)
      .withType(InterviewManagerStateType.Initializing, StateMatcher.returnState)
      .otherwise(() => {
        throw new Error('The InterviewManager has already been initialized');
      });

    if (state === null) {
      logger.warn('The InterviewManager is already initializing');
      return;
    }

    this.setState({
      type: InterviewManagerStateType.Initializing,
      sessionId: payload.sessionId,
      promptId: payload.promptId,
      imageId: payload.imageId,
      promptQuestion: payload.promptQuestion,
      recordings: [],
    });

    if (payload.mediaStream) {
      // Before initializing the sub state machines, first we need to validate if the input is valid
      // Validate the stream
      if (payload.mediaStream.active === false) {
        // Allow the user to initialize the interview manager again
        this.setState({
          type: InterviewManagerStateType.Empty,
        });
        throw new InactiveMediaStreamError();
      }

      // Validate the audio track
      if (payload.mediaStream.getAudioTracks().length === 0) {
        // Allow the user to initialize the interview manager again
        this.setState({
          type: InterviewManagerStateType.Empty,
        });
        throw new MissingMediaTrackError('audio');
      }

      // Validate the video track
      if (payload.type === 'video' && payload.mediaStream.getVideoTracks().length === 0) {
        // Allow the user to initialize the interview manager again
        this.setState({
          type: InterviewManagerStateType.Empty,
        });
        throw new MissingMediaTrackError('video');
      }
    }

    // Init the queue
    this.queue.initialize(payload.sessionId);
    this.sequenceId = (this.queue.getLatestSequenceId('recording') ?? -1) + 1;

    const existingSession = this.sessionRepository.getInterviewSession(payload.sessionId);

    // We can only recover existing sessions from the last 7 days
    if (existingSession != null && Date.now() - existingSession.createdAt >= INTERVIEW_SESSION_EXPIRATION_TIME_MS) {
      const error = new Error('The interview session is too old');
      this.setState({
        type: InterviewManagerStateType.UnrecoverableError,
        error,
        origin: 'manager',
        sessionId: payload.sessionId,
        promptId: payload.promptId,
        imageId: payload.imageId,
        promptQuestion: payload.promptQuestion,
        recordings: [],
      });
      throw error;
    }

    // Init the queue synchronizer
    if (existingSession == null) {
      await pRetry(() => this.recordingSessionService.startUpload(payload.sessionId, payload.promptId), {
        retries: 3,
      });
    }
    this.queueSynchronizer.initialize(payload);

    // Init the media recorder
    this.mediaStream.setStream(payload.mediaStream);

    if (payload.type === 'audio') {
      // Audio recording
      const customAudioRecorderConfig: Partial<RecordRTC.Options> = {};
      if (window.MediaRecorder.isTypeSupported('video/mp4')) {
        // In ios, the default recorder doesn't work sometimes (WebAssemblyRecorder).
        customAudioRecorderConfig.recorderType = RecordRTC.MediaStreamRecorder;
      }

      this.mediaRecorder.initialize(this.mediaStream, {
        type: 'audio',
        timeSlice: 2000,
        disableLogs: !this.debug,
        ...customAudioRecorderConfig,
      });
    } else {
      // Video recording
      const customVideoRecorderConfig: Partial<RecordRTC.Options> = {};
      if (window.MediaRecorder.isTypeSupported('video/webm;codecs=vp9')) {
        // This codec is used to record in android chrome.
        // The playback does not work with h264 for some reason.
        customVideoRecorderConfig.mimeType = 'video/webm;codecs=vp9';
      } else if (window.MediaRecorder.isTypeSupported('video/mp4')) {
        customVideoRecorderConfig.mimeType = 'video/mp4';
        // In ios, the default recorder doesn't work sometimes (WebAssemblyRecorder).
        customVideoRecorderConfig.recorderType = RecordRTC.MediaStreamRecorder;
      }

      this.mediaRecorder.initialize(this.mediaStream, {
        type: 'video',
        timeSlice: 2000,
        videoBitsPerSecond: 1100000,
        disableLogs: !this.debug,
        ...customVideoRecorderConfig,
      });
    }

    // Stop the recording when media recorder finishes.
    // If the interview manager is not in the finishing/finished state,
    // it indicates that the media recorder has been abruptly interrupted,
    // and we should transition to the error state.
    this.subscriptions.add(
      this.mediaRecorder.subscribeType(
        MediaRecorderStateType.Finished,
        () => {
          const state = matchState(this.getState())
            .withType(InterviewManagerStateType.Finishing, StateMatcher.returnNull)
            .withType(InterviewManagerStateType.Finished, StateMatcher.returnNull)
            .withType(InterviewManagerStateType.Empty, StateMatcher.returnNull)
            .withType(InterviewManagerStateType.Destroyed, StateMatcher.returnNull)
            .otherwise(StateMatcher.returnState);
          if (state === null) {
            return;
          }
          logger.warn('INTERVIEW_MANAGER.MEDIA_RECORDER_INTERRUPTED', { state });
          this.setState({
            ...state,
            type: InterviewManagerStateType.UnrecoverableError,
            origin: 'media-recorder',
            error: new RecordingInterruptedError('recorder-interrupted'),
          });

          if (this.mediaDataHealthCheckIntervalId) {
            clearInterval(this.mediaDataHealthCheckIntervalId);
            this.mediaDataHealthCheckIntervalId = null;
          }
        },
        { once: true },
      ),
    );

    // Register data listeners
    this.subscriptions.add(
      this.mediaRecorder.onDataAvailable(async (data) => {
        logger.debug('INTERVIEW_MANAGER.MEDIA_RECORDER.DATA_AVAILABLE', { size: data.size, type: data.type });

        this.lastMediaDataReceivedTimestamp = Date.now();
        this.recording.push(data);

        const state = this.getState();
        if (state.type === InterviewManagerStateType.Recording) {
          const currentDuration = this.duration + (Date.now() - state.startTimestamp);
          this.sessionRepository.setDuration(state.sessionId, currentDuration);
        }

        await this.enqueueMessage([
          {
            type: 'media.data',
            size: data.size,
            sequence: this.sequenceId++,
            timestamp: Date.now(),
          },
          data,
        ]);
      }),
    );

    if (existingSession == null) {
      // Create the session
      this.sessionRepository.createSession({
        sessionId: payload.sessionId,
        promptId: payload.promptId,
        imageId: payload.imageId,
        recordingType: payload.type,
        recorderUserRuid: payload.recorderUserRuid,
        recorderUserId: payload.recorderUserId,
        recorderPersonId: payload.recorderPersonId,
        promptQuestion: payload.promptQuestion,
        paused: true,
        createdAt: Date.now(),
      });

      // Start the session
      await this.enqueueMessage({
        type: 'session.start',
        sequence: this.sequenceId++,
        timestamp: Date.now(),
      });
    } else {
      if (existingSession.paused == false) {
        await this.enqueueMessage({
          type: 'media.stop',
          sequence: this.sequenceId++,
          timestamp: Date.now(),
        });
      }

      this.duration = existingSession.duration;
      this.promptSentTimestamp = existingSession.promptSentTimestamp;
    }

    // Update current state
    this.setState({
      type: InterviewManagerStateType.Paused,
      sessionId: payload.sessionId,
      promptId: payload.promptId,
      imageId: payload.imageId,
      promptQuestion: payload.promptQuestion,
      recordings: [],
    });
  }

  async recover(sessionId: string): Promise<void> {
    logger.info('RECOVERING', { sessionId });

    // Validate current state
    const state = matchState(this.getState())
      .withType(InterviewManagerStateType.Empty, StateMatcher.returnState)
      .withType(InterviewManagerStateType.Initializing, StateMatcher.returnNull)
      .otherwise(() => {
        throw new Error('The InterviewManager has already been initialized');
      });
    if (state == null) {
      logger.warn('The InterviewManager is already initializing');
      return;
    }

    const session = this.sessionRepository.getInterviewSession(sessionId);
    if (session == null) {
      throw new Error('Interview session not found');
    }

    this.setState({
      type: InterviewManagerStateType.Initializing,
      sessionId: session.sessionId,
      promptId: session.promptId,
      imageId: session.imageId,
      promptQuestion: session.promptQuestion,
      recordings: [],
    });

    // If the session is finished there's no data pending in queue, we are done.
    if (session.finished === true) {
      const size = await this.partitionQueueRepository.size(session.sessionId);
      if (size === 0) {
        await this.recordingSessionService.finishUpload(session.sessionId, session.promptId);
        await this.deleteSession(session.sessionId);
        this.setState({
          type: InterviewManagerStateType.Finished,
          sessionId: session.sessionId,
          promptId: session.promptId,
          imageId: session.imageId,
          promptQuestion: session.promptQuestion,
          recordings: [],
        });

        return;
      }
    }

    // Init the queue
    this.queue.initialize(sessionId);

    // Resume sequenceId
    this.sequenceId = (this.queue.getLatestSequenceId('recording') ?? 0) + 1;

    // Init the queue synchronizer
    this.queueSynchronizer.initialize({
      sessionId: session.sessionId,
      imageId: session.imageId,
      promptId: session.promptId,
      recorderUserId: session.recorderUserId,
      type: session.recordingType,
      recorderUserRuid: session.recorderUserRuid,
      recorderPersonId: session.recorderPersonId,
    });

    // Update current state
    this.setState({
      type: InterviewManagerStateType.Paused,
      sessionId: session.sessionId,
      promptId: session.promptId,
      imageId: session.imageId,
      promptQuestion: session.promptQuestion,
      recordings: [],
    });

    // If the resuming session was not paused, send the media.stop message
    if (!session.paused) {
      await this.enqueueMessage({
        type: 'media.stop',
        sequence: this.sequenceId++,
        timestamp: Date.now(),
      });
    }

    this.duration = session.duration;
    this.promptSentTimestamp = session.promptSentTimestamp;

    await this.endRecording();
  }

  /**
   * This method will try to gather as much information as possible about the
   * current recording. Should be used to try to identify the cause of the
   * no-data-received error, mainly in iOS Safari.
   */
  private getNoDataLogData(): Record<string, unknown> {
    const data: Record<string, unknown> = {};

    try {
      // Get interview manager internal props
      data.interviewManagerInternalState = {
        sequenceId: this.sequenceId,
        duration: this.duration,
        promptSentTimestamp: this.promptSentTimestamp,
        recordingLength: this.recording.length,
        recordingTotalSize: this.recording.reduce((total, blob) => blob.size + total, 0),
        lastMediaDataReceivedTimestamp: this.lastMediaDataReceivedTimestamp,
      };

      // Get document state
      data.document = {
        hidden: document.hidden,
        visibilityState: document.visibilityState,
        focus: document.hasFocus(),
      };

      // Get navigator data
      data.navigator = {
        ua: navigator.userAgent,
        standalone: 'standalone' in navigator ? navigator.standalone : undefined,
        inApp: isInApp(),
        online: navigator.onLine,
      };

      // Get stream state
      const mediaStreamState = this.mediaStream.getState();
      if (mediaStreamState.type === RecorderMediaStreamStateType.Streaming) {
        const stream = mediaStreamState.stream;
        data.mediaStreamId = stream.id;

        const mediaStreamTracks = [];
        for (const track of stream.getTracks()) {
          const trackSettings = track.getSettings();
          mediaStreamTracks.push({
            id: track.id,
            kind: track.kind,
            label: track.label,
            contentHint: track.contentHint,
            enabled: track.enabled,
            readyState: track.readyState,
            settings: JSON.parse(JSON.stringify(trackSettings)),
          });
        }

        data.mediaStreamTracks = mediaStreamTracks;
      }

      // Get recorder state
      const recorderState = this.mediaRecorder.getState();
      switch (recorderState.type) {
        case MediaRecorderStateType.Recording:
        case MediaRecorderStateType.Pausing:
        case MediaRecorderStateType.Finishing: {
          const recordRtc = recorderState.recorder;
          let internalRecorder = null;
          let blobFromGet = null;
          let allStates = null;

          // This library can throw everywhere, be careful
          try {
            internalRecorder = recordRtc.getInternalRecorder();
          } catch {}
          try {
            blobFromGet = recordRtc.getBlob();
          } catch {}

          try {
            if (
              internalRecorder != null &&
              'getAllStates' in internalRecorder &&
              typeof internalRecorder.getAllStates === 'function'
            ) {
              allStates = internalRecorder.getAllStates();
            }
          } catch {}

          data.recordRtc = {
            state: recordRtc.state,
            allStates,
            blobSize: recordRtc.blob?.size,
            getBlobSize: blobFromGet?.size,
            version: recordRtc.version,
            internalRecorder: JSON.parse(JSON.stringify(internalRecorder)),
          };
        }
      }
    } catch (error) {
      data.dataError = error;
    }

    return data;
  }

  async startRecording(): Promise<void> {
    logger.info('START_RECORDING');

    // Validate current state
    const state = matchState(this.getState())
      .withType(InterviewManagerStateType.Paused, StateMatcher.returnState)
      .withType(InterviewManagerStateType.Recording, StateMatcher.returnNull)
      .otherwise(() => {
        throw new Error('The InterviewManager is not in a valid state to start the recording');
      });
    if (state === null) {
      logger.warn('The InterviewManager is already recording');
      return;
    }

    // Update current state
    this.setState({
      ...state,
      type: InterviewManagerStateType.Recording,
      startTimestamp: Date.now(),
    });

    // Start the recording
    this.mediaRecorder.start();

    // Update session state - used on recovery
    this.sessionRepository.setPaused(state.sessionId, false);

    // Start the media data health check
    this.mediaDataHealthCheckIntervalId = setInterval(() => {
      // We should receive a media data every 5 seconds.
      // If more than 10 seconds passed since the last received data, we should stop the recording
      if (Date.now() - this.lastMediaDataReceivedTimestamp > 10000) {
        logger.warn('INTERVIEW_MANAGER.MEDIA_DATA_HEALTH_CHECK_FAILED', { state, ...this.getNoDataLogData() });
        this.setState({
          ...state,
          type: InterviewManagerStateType.UnrecoverableError,
          origin: 'media-recorder',
          error: new RecordingInterruptedError('no-data-received'),
        });

        // This recording is "done", we cannot continue recording anymore.
        // We can try to pause the recorder to cleanup some resources.
        // If it fails, it's not a problem.
        this.mediaRecorder.pause().catch(() => null);
        this.sessionRepository.setPaused(state.sessionId, true);

        if (this.mediaDataHealthCheckIntervalId) {
          clearInterval(this.mediaDataHealthCheckIntervalId);
          this.mediaDataHealthCheckIntervalId = null;
        }
      }
    }, 10000);

    // Start the stream session
    await this.enqueueMessage({
      type: 'media.start',
      sequence: this.sequenceId++,
      timestamp: Date.now(),
    });

    // Add screen lock
    if (navigator.wakeLock) {
      this.wakeLockPromise = navigator.wakeLock.request('screen');
    }
  }

  private getRecordingMimeType(type: string) {
    const isAudio = type.startsWith('audio/');

    if (window.MediaRecorder.isTypeSupported('video/mp4')) {
      if (isAudio) {
        if (type.startsWith('audio/wav')) {
          return 'audio/wav';
        }
        return 'audio/mp4';
      }
      return 'video/mp4';
    }

    return isAudio ? 'audio/webm' : 'video/webm';
  }

  private async getFullRecording(): Promise<Blob | null> {
    if (this.recording.length === 0) {
      return null;
    }

    const type = this.recording[0].type;
    let recording: Blob;

    // If the recording is audio/ogg, we cannot call fixWebmDuration.
    // It will break the data and will not be playable in firefox.
    // The audio should play just fine as is.
    if (type.startsWith('audio/ogg')) {
      return new Blob(this.recording, {
        type: 'audio/ogg',
      });
    }

    if (window.MediaRecorder.isTypeSupported('video/mp4')) {
      recording = new Blob(this.recording, {
        type: this.getRecordingMimeType(type),
      });
    } else {
      // https://bugs.chromium.org/p/chromium/issues/detail?id=642012
      recording = await fixWebmDuration(
        new Blob(this.recording, {
          type: this.getRecordingMimeType(type),
        }),
      ).catch((error) => {
        logger.error('INTERVIEW_MANAGER.FIX_WEBM_DURATION_FAILED', {}, error);
        // If for some reason this method fail, we can just concatenate the blob without fixing the duration.
        // Worst case scenario the video will play normally, without the total duration in the scrubber.
        return new Blob(this.recording, {
          type: this.getRecordingMimeType(type),
        });
      });
    }

    return recording;
  }

  async pauseRecording(): Promise<{ duration: number }> {
    logger.info('PAUSE_RECORDING');

    // Validate current state
    const state = matchState(this.getState())
      .withType(InterviewManagerStateType.Recording, StateMatcher.returnState)
      .withType(InterviewManagerStateType.Pausing, StateMatcher.returnNull)
      .withType(InterviewManagerStateType.Paused, StateMatcher.returnNull)
      .otherwise(() => {
        throw new Error('The InterviewManager is not in a valid state to pause the recording');
      });
    if (state === null) {
      logger.warn('The InterviewManager is already pausing/paused');
      return { duration: this.duration / 1000 - this.promptSentTimestamp };
    }

    // Update current state
    this.setState({
      ...state,
      type: InterviewManagerStateType.Pausing,
    });

    // Remove the interval health check
    if (this.mediaDataHealthCheckIntervalId !== null) {
      clearInterval(this.mediaDataHealthCheckIntervalId);
      this.mediaDataHealthCheckIntervalId = null;
    }

    const currentRecordingDuration = (Date.now() - state.startTimestamp) / 1000;

    // Pause the recording
    this.duration += Date.now() - state.startTimestamp;
    this.sessionRepository.setDuration(state.sessionId, this.duration);
    this.sessionRepository.setPaused(state.sessionId, true);
    await this.mediaRecorder.pause();

    await this.enqueueMessage({
      type: 'media.stop',
      sequence: this.sequenceId++,
      timestamp: Date.now(),
    });

    // Update current state
    const recording = await this.getFullRecording();

    this.setState({
      ...state,
      type: InterviewManagerStateType.Paused,
      recordings: [
        ...state.recordings,
        ...(recording != null
          ? [
              {
                duration: currentRecordingDuration,
                recording,
              },
            ]
          : []),
      ],
    });

    // Reset the in memory recording
    this.recording = [];

    return { duration: this.duration / 1000 - this.promptSentTimestamp };
  }

  async endRecording(): Promise<{ duration: number }> {
    logger.info('END_RECORDING');

    const recordingDuration = this.duration / 1000 - this.promptSentTimestamp;

    // Validate current state
    const state = matchState(this.getState())
      .withType(InterviewManagerStateType.Paused, StateMatcher.returnState)
      .withType(InterviewManagerStateType.Finishing, StateMatcher.returnNull)
      .withType(InterviewManagerStateType.Finished, StateMatcher.returnNull)
      .otherwise(() => {
        throw new Error('The InterviewManager is not in a valid state to end the recording');
      });
    if (state === null) {
      logger.warn('The InterviewManager is already finishing/finished');
      return { duration: recordingDuration };
    }

    // Update current state
    this.setState({
      ...state,
      type: InterviewManagerStateType.Finishing,
    });

    // Stop the recorder
    await this.mediaRecorder.stop();

    // Send the prompt
    await this.enqueueMessage({
      type: 'prompt',
      id: state.promptId + '-' + this.sequenceId,
      text: state.promptQuestion,
      timestampsRanges: [{ start: this.promptSentTimestamp, end: this.duration / 1000 }],
      sequence: this.sequenceId++,
      timestamp: Date.now(),
      index: 0,
    });

    // End the recording
    await this.enqueueMessage({
      type: 'session.end',
      sequence: this.sequenceId++,
      timestamp: Date.now(),
    });

    // Update the session
    this.sessionRepository.setFinished(state.sessionId);

    // Finish the websocket queue
    this.queueSynchronizer.finish();

    return { duration: recordingDuration };
  }

  async retakePrompt(): Promise<void> {
    logger.info('RETAKE_PROMPT');

    // Validate current state
    const state = matchState(this.getState())
      .withType(InterviewManagerStateType.Paused, StateMatcher.returnState)
      .otherwise(() => {
        throw new Error('The InterviewManager is not in a valid state to retake the prompt');
      });

    // Send the prompt
    const promptId = state.promptId + '-' + this.sequenceId;
    await this.enqueueMessage({
      type: 'prompt',
      id: promptId,
      text: state.promptQuestion,
      timestampsRanges: [{ start: this.promptSentTimestamp, end: this.duration / 1000 }],
      sequence: this.sequenceId++,
      timestamp: Date.now(),
      index: 0,
    });

    this.promptSentTimestamp = this.duration / 1000;
    this.sessionRepository.setPromptSentTimestamp(state.sessionId, this.promptSentTimestamp);

    // Clean up recordings
    this.setState({
      ...state,
      recordings: [],
    });

    // Delete the prompt
    await this.enqueueMessage({
      type: 'prompt.delete',
      sequence: this.sequenceId++,
      id: promptId,
      timestamp: Date.now(),
    });
  }

  getDuration() {
    const state = matchState(this.getState())
      .withType(InterviewManagerStateType.Recording, StateMatcher.returnState)
      .withType(InterviewManagerStateType.Pausing, StateMatcher.returnState)
      .withType(InterviewManagerStateType.Paused, StateMatcher.returnState)
      .otherwise(() => {
        throw new Error('The InterviewManager is not in a valid state to retrieve the remaining time');
      });

    switch (state.type) {
      case InterviewManagerStateType.Paused:
        return this.duration / 1000 - this.promptSentTimestamp;
      case InterviewManagerStateType.Pausing:
      case InterviewManagerStateType.Recording:
        return this.duration / 1000 - this.promptSentTimestamp + (Date.now() - state.startTimestamp) / 1000;
    }
  }

  getMaxRecordingDuration() {
    return this.maxRecordingDuration;
  }

  getRemainingRecordingDuration() {
    return this.maxRecordingDuration - this.getDuration();
  }

  async deleteSession(sessionId: string): Promise<void> {
    this.sessionRepository.deleteInterviewSession(sessionId);
    await this.partitionQueueRepository.clear(sessionId);
  }

  destroy(): void {
    logger.info('INTERVIEW_MANAGER.DESTROY');

    const state = this.getState();
    switch (state.type) {
      case InterviewManagerStateType.Finishing:
      case InterviewManagerStateType.Finished:
      case InterviewManagerStateType.Destroyed:
      case InterviewManagerStateType.UnrecoverableError:
        logger.info('INTERVIEW_MANAGER.DESTROY_IGNORED', state);
        return;
      default:
        break;
    }

    this.setState({
      ...state,
      type: InterviewManagerStateType.Destroyed,
    });

    this.subscriptions.destroy();

    if (this.wakeLockPromise) {
      this.wakeLockPromise.then((wakeLock) => {
        this.wakeLockPromise = null;
        if (!wakeLock.released) {
          wakeLock.release().catch((error) => logger.debug('INTERVIEW_MANAGER.WAKE_LOCK_RELEASE_FAILED', state, error));
        }
      });
    }

    if (this.mediaDataHealthCheckIntervalId) {
      clearInterval(this.mediaDataHealthCheckIntervalId);
      this.mediaDataHealthCheckIntervalId = null;
    }

    this.mediaRecorder.destroy();
    this.queueSynchronizer.destroy();
  }
}
